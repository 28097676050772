import { isExtractableFile, ReactNativeFile } from 'apollo-upload-client'
import moment, { Moment } from 'moment'
import momentTz from 'moment-timezone'
import * as Sentry from '@sentry/types'
import { $RootStore } from '../src/stores/RootStore'

export const mergeDeep = (target: any, source: any) => {
  const isObject = (obj: any) => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = sourceValue
    } else if (sourceValue instanceof Date) {
      target[key] = sourceValue
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue)
    } else if (sourceValue !== undefined && sourceValue !== null) {
      target[key] = sourceValue
    }
  })

  return target
}

export const generateObjectId = () => {
  const timestamp = (new Date().getTime() / 1000 | 0).toString(16) // eslint-disable-line no-bitwise

  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
    return (Math.random() * 16 | 0).toString(16) // eslint-disable-line no-bitwise
  }).toLowerCase()
}

/*
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * `wait` milliseconds.
 */
export const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const getDecimalHours = (date: Moment) => {
  const hours = date.get('hours')
  const minutes = date.get('minutes')

  return hours + (minutes / 60)
}

export const dayFormat = 'YYYY-MM-DD'
export const defaultTimezone = 'Europe/London'
export const defaultTimezoneOffset = '+0100'

export const dateToDay = function (date: Date | Moment) {
  return moment(date).startOf('day').format(dayFormat)
}

export function dateToDayOfWeek(date: Date | Moment) {
  return moment(date).startOf('day').day()
}

export function dateOffset(date: Date | Moment) {
  return moment(date).diff(moment().startOf('day'), 'days')
}

export const momentFromDay = function (date: string) {
  return moment(date, dayFormat).startOf('day')
}

export function generateSequence(min: number, max: number, incriment: number) {
  const options: Array<number> = []

  for (let i = min; i <= max; i += incriment) {
    options.push(i)
  }

  return options
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function nullCleanObject<T, K extends keyof T>(data: T | null | undefined, ...exceptions: K[]) {
  if (!data || Object.keys(data).some(x => (exceptions.some(exception => exception !== x) && data[x as keyof typeof data] === null))) {
    return null
  }
  return data as any
}

export function uniqueArray(array: Array<any>) {
  return Array.from(new Set(array))
}

export function nearestInArray(arr: Array<number>, target: number) {
  if (arr.length === 0) return undefined
  return arr.reduce((prev, curr) => Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev)
}

export const randomFromArray = <T>(arr: Array<T>) => {
  return arr[Math.floor((Math.random() * arr.length))]
}

export const triggerUserEvent_api = (rootStore: $RootStore, data: UserEventInput) => {
  const { communication: { requester } } = rootStore as any

  try {
    requester.triggerUserEvent({ data })
  } catch (e) {
  }
}

export function mapItems(items: Array<any>, key: string, value: string) {
  const map: Record<string, any> = {}

  items.forEach(item => {
    map[item[key]] = item[value]
  })

  return map
}

export function andJoin(strs: Array<string>) {
  return strs.reduce((previous, current, index, array) => {
    if (index === array.length - 1) {
      return previous + ' & ' + current
    } else {
      return previous + ', ' + current
    }
  }, '')
}

export const lightOrDarkHexColor = (color: string) => {
  const colors = +('0x' + color.slice(1).replace(color.length < 5 ? /./g : '', '$&$&'))

  const r = colors >> 16
  const g = colors >> 8 & 255
  const b = colors & 255

  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b),
  )

  if (hsp > 127.5) {
    return 'light'
  } else {
    return 'dark'
  }
}

export function getBrowserTimezone() {
  return Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Europe/London'
}

export const groupBy = function <T extends Record<string, any>>(items: T[], key: string, valueMap?: any[]): Record<string, T[]> {
  return (valueMap || items).reduce(function (rv, x, i) {
    (rv[x[key]] = rv[x[key]] || []).push(items[i])
    return rv
  }, {})
}

export const callBackUrlSlugs = {
  manchester: 'secret-spa-onboarding-zoom-call-manchester',
  brighton: 'secret-spa-on-boarding-zoom-call-brighton',
}

export function firstParam(param: string | string[] | undefined) {
  if (param === undefined || param === null) return undefined
  if (Array.isArray(param)) return param[0]
  return param
}

export function isExtractableFileButNotBlob(file: any): file is File | ReactNativeFile {
  return isExtractableFile(file) && !!(file as any).name
}

export function dayAndHourToDate(day: string, hourDecimal: number, offset = defaultTimezoneOffset) {
  const minutes = Math.floor((hourDecimal % 1) * 60)
  const hour = Math.floor(hourDecimal)

  return momentTz(`${day} ${hour}:${minutes} ${offset}`, `${dayFormat} HH:mm Z`).toDate()
}

export function dateInTimezone(date: Date | undefined, timezone = defaultTimezone) {
  return momentTz(date).tz(timezone)
}

export function timeBrackets(date: Moment, duration: number) {
  const startTime = date.format('HH:mm')
  if (duration === 0) return startTime
  const endTime = momentTz(date).add(duration, 'minutes').format('HH:mm')
  return `${startTime} - ${endTime}`
}

export function getSentryErrorData(event: Sentry.Event) {
  const firstException: Sentry.Exception | undefined = (event.exception?.values || [])[0]

  return {
    message: event.message,
    exception: firstException?.value,
    event_id: event.event_id,
  }
}

export const fullNameWithOption = (clientNameOption: 'Full' | 'Short' | 'Initials', fullName: string, defaultName = 'Anonymous') => {
  const names = fullName.split(' ')

  const firstName = names[0] || defaultName
  const lastName = names[1] || ''

  return nameWithOption(clientNameOption, firstName, lastName)
}

export const nameWithOption = (clientNameOption: 'Full' | 'Short' | 'Initials', firstName: string, lastName: string) => {
  let str = ''

  const firstInitial = (firstName[0] ?? '').toUpperCase()
  const lastInitial = (lastName[0] ?? '').toUpperCase()

  switch (clientNameOption) {
    case 'Full':
      str = `${firstName} ${lastName}`
      break
    case 'Short':
      str = `${firstName} ${lastInitial}`
      break
    case 'Initials':
      str = `${firstInitial}${lastInitial}`
      break
  }

  return str.trim()
}
export function doublePressGuard(fn: any, duration = 1000) {
  let lastPressedAt = 0

  return () => {
    const msSinceLastPress = Date.now() - lastPressedAt
    if (duration > msSinceLastPress) {
      return
    }
    lastPressedAt = Date.now()

    fn.call()
  }
}

export function enumEquals<T>(matches: Array<T>, value: T) {
  return matches.includes(value)
}
